@media screen and (min-width: 1100px) {
  .marginTop {
    margin-top: -15rem;
  }

  .parent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 16px;
  }
  .div1 {
    grid-area: 1 / 1 / 4 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .div3 {
    grid-area: 2 / 2 / 4 / 3;
  }
  .div4 {
    grid-area: 1 / 3 / 2 / 4;
  }
  .div5 {
    grid-area: 2 / 3 / 4 / 4;
  }
}
