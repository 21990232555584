p.p1 {
    margin: 0.0px 0.0px 0.0px 0.0px;
    font: 19.0px Helvetica;
    color: #000000
}

p.p2 {
    margin: 0.0px 0.0px 0.0px 0.0px;
    font: 19.0px Helvetica;
    color: #000000;
    min-height: 14.0px
}

p.p3 {
    margin: 0.0px 0.0px 0.0px 36.0px;
    font: 19.0px Helvetica;
    color: #000000
}

p.p4 {
    margin: 0.0px 0.0px 0.0px 36.0px;
    font: 19.0px Helvetica;
    color: #000000;
    min-height: 14.0px
}

span.s1 {
    text-decoration: underline;
    color: #0b4cb4
}