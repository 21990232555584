.underlineCustom {
    position: relative;
    color: white;
    text-decoration: none;
  }
  
  .underlineCustom:hover {
    color: white;
  }
  
  .underlineCustom::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: white;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .underlineCustom:hover::before {
    transform: scaleX(1);
  }

  .overflowHidden {
  overflow: hidden;
  margin: 0;
  touch-action: none;
  -ms-touch-action: none;
  /* position: fixed; we get rid of this line which resets the flow of the page */
  /* height: 100%; we change 100% to 100vh */ 
  height: 100vh;
}