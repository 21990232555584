.hideMenuNav {
    display: none;
  }
.showMenuNav {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    background: #485696;
    z-index: 10;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }